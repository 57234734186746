import { Avatar, Box } from "@mui/material";
import { textUsColors } from "@tesseract/theme/src/palettes/colors";
import { DirectoryTreeProps } from "./types";

function DirectoryTree({
  icon,
  rootComponent,
  childComponents,
}: DirectoryTreeProps) {
  const renderIcon = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "36px",
          backgroundColor: (theme) => {
            return theme.palette.background.default;
          },
        }}
      >
        <Avatar sx={{ bgcolor: "blueGrey.800", width: "32px", height: "32px" }}>
          {icon}
        </Avatar>
      </Box>
    );
  };
  const renderChildren = () => {
    const style = {
      marginLeft: "15px",
      borderStyle: "none none solid solid",
      borderRadius: "0 0 0 0.5rem",
      borderColor: textUsColors.grayscale[400],
      height: "28px",
    };

    const lastChildStyle = {
      marginTop: "calc(-1000000px + 18px)",
      height: "1000000px",
      zIndex: "-1",
    };

    return childComponents.map((child, index) => {
      const key = `keyword${index}`;
      return (
        <Box
          key={key}
          sx={{
            display: "grid",
            gridTemplateColumns: "calc(2rem + 40px) 1fr",
            marginBottom: "16px",
          }}
        >
          <Box
            sx={
              index === childComponents.length - 1
                ? {
                    ...style,
                    ...lastChildStyle,
                  }
                : style
            }
          />
          {child}
        </Box>
      );
    });
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "32px 30px 1fr",
        gap: "8px",
        overflow: "hidden",
      }}
    >
      {renderIcon()}
      <Box
        sx={{
          display: "grid",
          gridArea: "1 / 2 / 2 / 4",
        }}
      >
        {rootComponent}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gridArea: "2 / 1 / 4 / 4",
        }}
      >
        {renderChildren()}
      </Box>
    </div>
  );
}

export { DirectoryTree };
