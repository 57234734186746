import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";

function AddKeywordAnswerButton() {
  return (
    <Button
      startIcon={<AddIcon />}
      color="primary"
      sx={{
        height: "36px",
        width: "216px",
        "&:hover": { backgroundColor: "transparent" },
      }}
      data-testid="add-keyword-answer-button"
    >
      Add Keyword Answer
    </Button>
  );
}

export default AddKeywordAnswerButton;
