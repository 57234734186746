import { Box, TextField, Typography } from "@mui/material";
import { Field } from "formik";
import NewMessage from "containers/NewMessage";
import { RootKeywordFormProps } from "features/Keywords/types";
import AutomaticMessageIndicator from "components/AutomaticMessageIndicator";

function RootKeywordForm({ errors, touched }: RootKeywordFormProps) {
  return (
    <Box marginLeft="8px">
      <Typography component="h4" variant="h4">
        Keyword Trigger
      </Typography>
      <Box
        sx={{
          border: "1px solid #0000001F",
          borderRadius: "5px",
          marginTop: "1rem",
          width: "48rem",
        }}
      >
        <Field name="title" type="text">
          {({ field }) => {
            return (
              <TextField
                {...field}
                hiddenLabel
                autoFocus
                data-testid="textus-keyword-trigger"
                error={touched.title && !!errors.title}
                fullWidth
                helperText={touched.title && errors.title}
                id="textus-Campaigns-title"
                placeholder="Keyword"
                type="text"
                variant="outlined"
                sx={{
                  margin: "1rem 1rem 0 1rem",
                  maxWidth: "736px",
                }}
              />
            );
          }}
        </Field>
        <NewMessage
          data-testid="root-keyword-auto-response"
          activateSignature
          renderSubmitButton={() => {}}
          placeholder="Message"
          disabled={false}
          showContactDetailsSidebar={false}
          recipients={[]}
          unsubscribed={false}
          displaySegmentCount={false}
        />
        <AutomaticMessageIndicator />
      </Box>
    </Box>
  );
}

export default RootKeywordForm;
