import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import { DirectoryTree } from "components/DirectoryTree";
import { DialogFooter } from "components/DialogFooter";
import PageHeader from "components/Page/PageHeader";
import PageContent from "components/Page/PageContent";
import { KeywordFormProps } from "features/Keywords/types";
import RootKeywordForm from "./RootKeywordForm";
import AddKeywordAnswerButton from "./AddKeywordAnswerButton";

function Item() {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<KeyIcon />}>
        <Typography variant="h6" data-testid="abc-campaign-accordion">
          TEST
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>Just some regular keyword stuff</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default function KeywordForm({
  isSinglePanel,
  showSidebar,
  toggleSidebar,
}: KeywordFormProps) {
  const history = useHistory();
  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);
  const isConfirmDisabled = true; // TODO: Implement validation when form is complete

  const icon = <KeyIcon sx={{ fontSize: 20 }} />;

  return (
    <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
      <PageHeader title="Create Keyword" toggleSidebar={toggleSidebar} />
      <Box>
        <Formik initialValues={{}} onSubmit={onCancel}>
          {({ errors, touched, isSubmitting, isValid, setFieldValue }) => {
            return (
              <Form>
                <Box style={{ margin: "40px 0px 0px 96px" }}>
                  <Typography component="h3" variant="h3" marginBottom="40px">
                    New Automation
                  </Typography>
                  <Box>
                    <DirectoryTree
                      icon={icon}
                      rootComponent={
                        <RootKeywordForm
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                        />
                      }
                      childComponents={[
                        <Item key="test1" />,
                        <Item key="test2" />,
                        <AddKeywordAnswerButton />,
                      ]}
                    />
                  </Box>
                </Box>
                <Box>
                  <div
                    style={{
                      display: "block",
                      padding: "20px",
                      height: "60px",
                      width: "100%",
                    }}
                  />
                  <DialogFooter
                    onCancel={onCancel}
                    confirmText="Create"
                    isConfirmDisabled={
                      isSubmitting || isValid || isConfirmDisabled
                    }
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </PageContent>
  );
}
